 pre,code {
    border: 1px solid #dedede;
    border-radius: 3px;
    background-color: #eef;
    word-wrap: break-word;
}

code {
    padding: 0 3px;
}

pre {
    padding: 8px 13px;
    overflow-x: scroll;
    font-size: 12px;
    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
        background-color: transparent;
    }
}