
@import 'custom/variables',
        'custom/code',
        'syntax-highlighting';

body {
    color: $font-color;
    font-size: $font-size-base;
}

h2,
h3 {
    margin: 1em 0 0.5em;
    &.section-title {
        margin-top: 0;
    }
    u {
        text-decoration: none;
        border-bottom: 1px solid #fff;
    }
}

h4 {
    margin: 0 0 0.6em;
}

p {
    font-size: $font-paragraph;
    margin-bottom: 12px;
}

a {
    font-weight: 400;
}

hr {
    margin: 2rem 0;
}

pre {
    overflow-x: auto;
}

.page-header {
    background-color: $panel-darkest;
    background-size: auto 130%;
    &.page-header-small {
        min-height: 25vh !important;
        h1{
            margin: 70px 0 0;
            padding-bottom: 1rem;
        }
    }
    .filter::after {
        background-color: rgba(0, 0, 0, 0.78);
    }
    .motto {
        margin: 0.5em 0;
    }
}

.navbar {
    .navbar-collapse {
        &:after {
            background-color: #fff;
        }
    }
}

.dropdown-menu {
    min-width: 13rem;
    .dropdown-item {
        white-space: normal;
    }
}

#feature-image-container {
    border-top: $navbar-height solid $panel-darkest;
    background-color: $panel-darkest;
    background-position: center center;
    background-size: cover;
    #feature-image {
        position: relative;
        img {
            max-height: 350px;
            z-index: 2;
            position: relative;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: 1;
            background-color: rgba(0, 0, 0, 0.85);
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
        }
    }
}

.content-tabs {
    list-style: none;
    padding: 0;
    margin-left: 0;
    clear: both;
    overflow: auto;
    text-transform: uppercase;
    font-size: .8em;
    letter-spacing: 0.1em;
    li {
        margin: 0 10px 0 0;
        padding: 0 10px 0 0;
        float: left;
        line-height: 2;
        border-right: 1px solid $border-light;
        &:first-child {
            padding-left: 0;
        }
        &:last-child {
            border-right: none;
        }
        a {
            text-decoration: none;
        }
    }
}

.content-image {
    &.pull-left {
        margin-right: 20px;
        margin-bottom: 20px;
    }
    &.pull-right {
        margin-left: 20px;
        margin-bottom: 20px;
    }
    &.shadow {
        box-shadow: 2px 2px 5px rgba(0,0,0,0.35);
        margin-right: 25px;
    }
}

#hire-me-banner {
    color: #fff;
    margin: 0 0 25px;
    &>div {
        padding: 40px 50px;
        h2 {
            margin-top: 10px;
        }
        a {
            color: #fff;
        }
    }
    #full-time {
        background-color: $panel-darkest;
        strong {
            opacity: 0.5;
        }
    }
    #part-time {
        background-color: $panel-darkest;
    }
    #connect {
        background-color: $panel-darker;
    }
}

.btn-primary {
    background-color: #f5593d;
    border-color: #f5593d;
}

ul.quotes {
    list-style: none;
    margin: 1.5em 0;
    padding: 0;
    li {
        margin-bottom: 1.25em;
        blockquote {
            border: none;
            color: $text-color-light;
            font-style: italic;
            margin: 0;
            border-left: 2px solid #f1e3e3;
            padding-left: 16px;
        }
    }
}

.highlight {
    background-color: $panel-lightest;
}

.resources-panel {
    padding: 2rem;
    padding-bottom: 0;
    .caption {
        margin: 10px 0 0;
        font-size: 12px;
        margin-bottom: 20px;
    }
}

.info {
    .icon {
        text-align: center;
    }
}

.section-dark {
    .fa {
        color: #fff;
    }
}

@media (min-width: $screen-width-tablet) {
    .resources-panel.side {
        float: right;
        margin-left: 30px;
        max-width: 200px;
    }
}

@media screen and (max-width: 991px) {
    .dropdown {
        .dropdown-menu {
            .dropdown-item {
                padding: 8px 35px 7px 10px;
                &:hover {
                    color: #f5593d !important;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .page-header {
        &.page-header-small {
            h1 {
                font-size: 2.2em;
            }
        }
    }
}