$navbar-height: 95px;

$font-size-base: 18px;
$font-paragraph: 18px;

$font-color: #2e2b28;
$border-light: #eceaea;
$panel-lightest: #f1f1f1;
$panel-darker: #4a4a4a;
$panel-darkest: #232020;
$text-color: #232020;
$text-color-light: lighten($text-color, 50%);

$screen-width-tablet: 768px;

